ACC.paginationsort = {
  downUpKeysPressed: false,
  articlesPageSize: 24,
  articlesCurrentPage: 0,
  otherContentPageSize: 24,
  otherContentCurrentPage: 0,
  articlesSort: 'newest',
  otherContentSort: 'name-asc',
  currentTab: null,

  bindAll: function() {
    var formsIdArr = ['sort_form1', 'sort_form2', 'page_form1', 'page_form2'];
    this.bindSortForms(formsIdArr);
    this.addPaginationListeners();
  },

  bindSortForms: function(formsIdArr) {
    formsIdArr.forEach(function (formId) {
      $(document).on('change', 'form#' + formId, function() {
        var inputElem = $(this).find('input[name=q]');
        inputElem.val(decodeURIComponent(inputElem.val()));
        this.submit();
      });
    })
  },

  addPaginationListeners: function() {
    this.addArticlesListeners();
    this.addOtherContentListeners();
  },

  addArticlesListeners: function() {
    $('#articles_page_form1, #articles_page_form2').change(function() {
      var form = $(this);
      if (typeof form.serializeArray === 'function') {
        var formFields = form.serializeArray();

        if (formFields.length) {
          ACC.paginationsort.articlesPageSize = formFields[0].value;
          ACC.paginationsort.articlesCurrentPage = 0;
          ACC.paginationsort.passPageSize = true;
          // Emulate a tab click to refresh the data
          $('#search-results__articles-tab').click();
        }
      }
    });

    $('#articles_page_sort_form1, #articles_page_sort_form2').change(function() {
      var form = $(this);
      if (typeof form.serializeArray === 'function') {
        var formFields = form.serializeArray();

        if (formFields.length) {
          ACC.paginationsort.articlesSort = formFields[0].value;

          // Emulate a tab click to refresh the data
          $('#search-results__articles-tab').click();
        }
      }
    });

    // Previous button
    $('#articles-search-page-prev').click(function(e) {
      ACC.paginationsort.articlesCurrentPage--;

      // Emulate a tab click to refresh the data
      $('#search-results__articles-tab').click();
    });

    // Next button
    $('#articles-search-page-next').click(function(e) {
      ACC.paginationsort.articlesCurrentPage++;

      // Emulate a tab click to refresh the data
      $('#search-results__articles-tab').click();
    });

    // Individual page numbers
    $('.articles-search-page-number').click(function(e) {
      ACC.paginationsort.articlesCurrentPage = $(e.target).data('page-number') - 1;

      // Emulate a tab click to refresh the data
      $('#search-results__articles-tab').click();
    });
  },

  addOtherContentListeners: function() {
    $('#other_content_page_form1, #other_content_page_form2').change(function() {
      var form = $(this);
      if (typeof form.serializeArray === 'function') {
        var formFields = form.serializeArray();

        if (formFields.length) {
          ACC.paginationsort.otherContentPageSize = formFields[0].value;
          ACC.paginationsort.otherContentCurrentPage = 0;
          ACC.paginationsort.passPageSize = true;

          // Emulate a tab click to refresh the data
          $('#search-results__other-content-tab').click();
        }
      }
    });

    $('#othercontent_page_sort_form1, #othercontent_page_sort_form2').change(function() {
      var form = $(this);
      if (typeof form.serializeArray === 'function') {
        var formFields = form.serializeArray();

        if (formFields.length) {
          ACC.paginationsort.otherContentSort = formFields[0].value;

          // Emulate a tab click to refresh the data
          $('#search-results__other-content-tab').click();
        }
      }
    });

    // Previous button
    $('#other-content-search-page-prev').click(function(e) {
      ACC.paginationsort.otherContentCurrentPage--;

      // Emulate a tab click to refresh the data
      $('#search-results__other-content-tab').click();
    });

    // Next button
    $('#other-content-search-page-next').click(function(e) {
      ACC.paginationsort.otherContentCurrentPage++;

      // Emulate a tab click to refresh the data
      $('#search-results__other-content-tab').click();
    });

    // Individual page numbers
    $('.other-content-search-page-number').click(function(e) {
      ACC.paginationsort.otherContentCurrentPage = $(e.target).data('page-number') - 1;

      // Emulate a tab click to refresh the data
      $('#search-results__other-content-tab').click();
    });
  },
};

$(function() {
  ACC.paginationsort.bindAll();
});
