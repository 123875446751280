/**
 * Address Lookup
 *
 * @author Deepak
 *
 */
$(document).ready(function () {
  var __addressData = [];
  var __lookupKey = 'lookupIndex-';
  var __formType;
  var enableAddressLookUpHouseNumber;
  var postcodeFormField = $('input[name="postcode"], input[name="billingAddress.postcode"]');
  postcodeFormField.on('blur change', function () {
    var postcode = postcodeFormField.val().toUpperCase();
    updateField(postcodeFormField, postcode);
  });
  postcodeFormField.on('focus', function () {
    $('.address-lookup-trigger').removeAttr('disabled');
    $('.address-lookup-error').addClass('hide');
    $('.address-lookup-success').addClass('hide');
    $('.lookup-suggestions').removeClass('active');
    $('#postcode\\.errors').addClass('hide');
  });

  // NOTE : Implementation using lookup key index to avoid re-parsing of JSON object to string and vice verse).

  // Bind lookup api trigger

  $('.address-lookup-trigger').on('click', function (event) {
    event.preventDefault();

    var $buttonRef = $(this);
    $buttonRef.attr('disabled', true);
    var $buttonMessage = $buttonRef.find('span').last();
    var api_endpoint = $(this).attr('data-api-endpoint');
    var api_processing_msg = $(this).attr('data-api-process-message');
    var postcodeFieldName = $(this).attr('data-api-postcode-field-name');
    var housenumberFieldName = $(this).attr('data-api-housenumber-field-name');
    var lookupParameter = $(this).attr('data-api-country-field-check')
      ? $(this).attr('data-api-country-field-check')
      : false;
    enableAddressLookUpHouseNumber = JSON.parse(lookupParameter);
    __formType = $(this).attr('data-api-form-type');
    var formField = $('input[name="' + postcodeFieldName + '"]');
    var postcode = formField
      .val()
      .toUpperCase()
      .replace(/^\s+|\s+$/gm, '');
    updateField(formField, postcode);
    if (enableAddressLookUpHouseNumber) {
      var houseFormField = $('input[name="' + housenumberFieldName + '"]');
      var housenumber = houseFormField.val().toUpperCase();
      houseFormField.val(housenumber);
    }
    var originalText = $buttonMessage.text();
    var serverSideErrorMessage = $('.form__item--error').find("span[id='postcode.errors']");
    var serverSideHouseNumberErrorMessage = $('.form__item--error').find("span[id='houseNumber.errors']");
    serverSideErrorMessage.hide(0, function () {
      serverSideErrorMessage
        .closest('div.form__item--error')
        .removeClass('form__item--error')
        .removeClass('alert-icon-after')
        .addClass('form-group')
        .addClass('required');
    });
    serverSideHouseNumberErrorMessage.hide(0, function () {
      serverSideHouseNumberErrorMessage
        .closest('div.form__item--error')
        .removeClass('form__item--error')
        .removeClass('alert-icon-after')
        .addClass('form-group')
        .addClass('required');
    });

    $buttonRef.addClass('searching');
    $buttonMessage.text(api_processing_msg); // Change button message
    let payloadData = {};
    payloadData.payload = postcode;
    if (enableAddressLookUpHouseNumber) {
      payloadData.housenumber = housenumber;
    }
    $.ajax({
      url: api_endpoint,
      method: 'POST',
      data: payloadData,
      success: function (response, status, jqXHR) {
        $buttonRef.removeAttr('disabled');
        const data = response.data ? $.parseJSON(response.data) : '';
        if (!$.isEmptyObject(data)) {
          $('.address-lookup-error').addClass('hide');
          $('.address-lookup-success').removeClass('hide');
          $('.address-lookup-target-container').removeClass('hide');
          if (enableAddressLookUpHouseNumber) {
            populateAddressFormFields(__formType, data[0]);
          } else {
            populateAddressSuggestions(data);
          }
          $('.lookup-suggestions').addClass('active');
          __addressData = data;
        } else {
          $('.address-lookup-error').removeClass('hide');
          $('.address-lookup-success').addClass('hide');
          $('.address-lookup-target-container').addClass('hide');
          $('.lookup-suggestions').removeClass('active');
          if (enableAddressLookUpHouseNumber) {
            clearShippingFormFields();
            clearBillingFormFields();
          }
        }
      },
      error: function (response, status, jqXHR) {
        $('.address-lookup-error').removeClass('hide');
        $('.address-lookup-success').addClass('hide');
        $('.address-lookup-target-container').addClass('hide');
        $('.lookup-suggestions').removeClass('active');
      },
    }).done(function (data) {
      setTimeout(function () {
        $buttonRef.removeClass('searching');
        $buttonMessage.text(originalText);
      }, 500);
    });
  });

  // Bind lookup api target
  $('.address-lookup-target').on('change', function (event) {
    var optionSelected = $('option:selected', this);
    var valueSelected = this.value;
    var optionSelectedText = $(this).text();
    $.each(__addressData, function (key, value) {
      if (__lookupKey + key == valueSelected) {
        populateAddressFormFields(__formType, value);
      }
    });
    $('.lookup-suggestions').removeClass('active');
  });

  // Populate select list of targets
  function populateAddressSuggestions(data) {
    if (!$.isEmptyObject(data)) {
      $('.address-lookup-target').find('option:gt(0)').remove(); // empty previous address options
      $('.address-lookup-target').val($('.address-lookup-target option:first').val());
      $.each(data, function (key, value) {
        $('.address-lookup-target').append(
          $('<option></option>')
            .val(__lookupKey + key)
            .html(computeAddressLines(value))
        );
      });
      $('.address-lookup-target option:first').trigger('change', function (event) {});
    }
  }

  // Parse response
  function computeAddressLines(obj) {
    var concatString = '';
    var seperator = ', ';
    if (!$.isEmptyObject(obj)) {
      if ('line1' in obj && obj.line1.length > 0) {
        concatString += obj.line1 + seperator;
      }
      if ('line2' in obj && obj.line2.length > 0) {
        concatString += obj.line2 + seperator;
      }
      if ('line3' in obj && obj.line3.length > 0) {
        concatString += obj.line3 + seperator;
      }
      if ('town' in obj && obj.town.length > 0) {
        concatString += obj.town + seperator;
      }
      if ('county' in obj && obj.county.length > 0) {
        concatString += obj.county + seperator;
      }
      if ('postcode' in obj && obj.postcode.length > 0) {
        concatString += obj.postcode;
      }
    }
    return concatString;
  }

  // Populate address form fields (based on form type)
  function populateAddressFormFields(formType, obj) {
    switch (formType) {
      case 'billing':
        clearBillingFormFields();
        populateBillingFormFields(obj);
        break;
      case 'shipping':
        clearShippingFormFields();
        populateShippingFormFields(obj);
        break;
      case 'account':
        clearShippingFormFields();
        populateShippingFormFields(obj);
        break;
      default:
        clearShippingFormFields();
        populateShippingFormFields(obj);
        break;
    }
  }

  // Populate fields on the billing address form
  function populateBillingFormFields(obj) {
    var line1 = $('input[name="billingAddress.line1"]'),
      line2 = $('input[name="billingAddress.line2"]'),
      line3 = $('input[name="billingAddress.line3"]'),
      housenumberField = $('input[name="billingAddress.houseNumber"]'),
      town = $('input[name="billingAddress.townCity"]');

    if (!$.isEmptyObject(obj)) {
      if ('line1' in obj && obj.line1.length > 0) {
        line1.val(obj.line1);
      } else {
        line1.val('');
      }
      if ('line2' in obj && obj.line2.length > 0) {
        line2.val(obj.line2);
      } else {
        line2.val('');
      }
      if ('line3' in obj && obj.line3.length > 0) {
        line3.val(obj.line3);
      } else {
        line3.val('');
      }
      if ('housenumber' in obj && obj.housenumber.length > 0) {
        housenumberField.val(obj.housenumber);
      } else {
        housenumberField.val('');
      }
      if ('town' in obj && obj.town.length > 0) {
        town.val(obj.town);
      } else {
        town.val('');
      }
      setCounty(obj, 'billingAddress.regionIso', 'select_bill_to_address_state');
    }
  }

  function setCounty(obj, name, selectId) {
    var isCountySet = false;
    var $select = $('select[name="' + name + '"] option');
    var selectedCounty = '',
      $option = '';
    if ('county' in obj && obj.county.length > 0) {
      selectedCounty = obj.county;
      if (enableAddressLookUpHouseNumber) {
        $option = $select.filter(function () {
          return $(this).val() == selectedCounty;
        });
      } else {
        $option = $select.filter(function () {
          return $(this).text() == selectedCounty;
        });
      }
      if ($option.length > 0) {
        $option.prop('selected', true);
        $('#' + selectId).text($option[0].innerText);
        isCountySet = true;
      }
    }
    if (!isCountySet) {
      var $naOption = $select.find('option:nth-child(2)');
      $naOption.attr('selected', 'selected');
      $('#' + selectId).text($naOption.text());
    }
  }

  // Populate fields on the shipping address form
  function populateShippingFormFields(obj) {
    var line1 = $('input[name="line1"]'),
      line2 = $('input[name="line2"]'),
      line3 = $('input[name="line3"]'),
      housenumberField = $('input[name="houseNumber"]'),
      town = $('input[name="townCity"]');
    if (!$.isEmptyObject(obj)) {
      if ('line1' in obj && obj.line1.length > 0) {
        updateField(line1, obj.line1);
      } else {
        updateField(line1, '');
      }
      if ('line2' in obj && obj.line2.length > 0) {
        updateField(line2, obj.line2);
      } else {
        updateField(line2, '');
      }
      if ('line3' in obj && obj.line3.length > 0) {
        updateField(line3, obj.line3);
      } else {
        updateField(line3, '');
      }
      if ('housenumber' in obj && obj.housenumber.length > 0) {
        updateField(housenumberField, obj.housenumber);
      } else {
        updateField(housenumberField, '');
      }
      if ('town' in obj && obj.town.length > 0) {
        updateField(town, obj.town);
      } else {
        updateField(town, '');
      }
      setCounty(obj, 'regionIso', 'select_regionIso');
    }
  }

  // Clear shipping form fields
  function clearShippingFormFields() {
    $('select[name="regionIso"]').val($('select[name="regionIso"] option:first').val());
    $('select[name="regionIso"] option:first').trigger('change', function (event) {});
    if (enableAddressLookUpHouseNumber) {
      $('input[name="townCity"]').val('');
      $('input[name="line1"]').val('');
    }
  }

  // Clear billing form fields
  function clearBillingFormFields() {
    $('select[name="billingAddress.regionIso"]').val($('select[name="billingAddress.regionIso"] option:first').val());
    $('select[name="billingAddress.regionIso"] option:first').trigger('change', function (event) {});
    if (enableAddressLookUpHouseNumber) {
      $('input[name="billingAddress.townCity"]').val('');
      $('input[name="billingAddress.line1"]').val('');
    }
  }

  function updateField($field, value, event = new Event('input')) {
    $field.val(value);
    for (let i = 0; i < $field.length; i++) {
      $field[i].dispatchEvent(event);
    }
  }
});
