/**
 * A few initial things we need to do for the page before DOM is ready.
 */
ACC.storefinder = {
  map: '',
  bindAll: function () {
    //No need to use chart API to generate marker images
    //this.getStoreMarkersImages();
    this.bindFindStoresNearMe();
  },

  getStoreMarkersImages: function () {
    if ($('.store-locator__stores').length != 0) {
      $('.map-id').each(function (i) {
        $(this).html(String.fromCharCode(i + 65));
      });
    }
  },
  bindFindStoresNearMe: function () {
    $(document).on('click', '#findStoresNearMe', function (e) {
      e.preventDefault();

      var gps = navigator.geolocation;

      if (gps) {
        gps.getCurrentPosition(ACC.storefinder.positionSuccessStoresNearMe, function (error) {
          // console.log("An error occurred... The error code and message are: " + error.code + "/" + error.message);
        });
      }
    });
  },

  positionSuccessStoresNearMe: function (position) {
    $('#latitude').val(position.coords.latitude);
    $('#longitude').val(position.coords.longitude);
    $('#nearMeStorefinderForm').submit();
    return false;
  },
};
const getQueryString = function (field, url) {
  const href = url ? url : window.location.href;
  const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  const string = reg.exec(href);
  return string ? string[1] : null;
};
const getQueryParameters = function () {
  return document.location.search
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      function (n) {
        return (n = n.split('=')), (this[n[0]] = n[1]), this;
      }.bind({})
    )[0];
};
const getFrameQueryParameters = function (frameQuery) {
  return frameQuery
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      function (n) {
        return (n = n.split('=')), (this[n[0]] = n[1]), this;
      }.bind({})
    )[0];
};
var map;
let lastSearchedBounds;
let idledZoom;
let initial = true;
let center;
let distanceUnit;
let expandSearch = true;

$(document).ready(function () {
  if (typeof google != 'undefined') {
    infowindow = new google.maps.InfoWindow({
      disableAutoPan: true,
    });
  }

  ACC.storefinder.bindAll();
  if (typeof BMap != 'undefined') {
    TextMarker.prototype = new BMap.Overlay();
    if (typeof drawBaiduMap != 'undefined') {
      drawBaiduMap();
    }
  }

  // This is for the map on the LIST OF STORES page.
  if (typeof drawStoresFinal != 'undefined') {
    drawStoresFinal();
  }

  // This is for the map on the STORE profile page.
  if (typeof drawStoreFinal != 'undefined') {
    drawStoreFinal();
  }
});

const calculateDistance = function (lat1, lon1, lat2, lon2, unit) {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == 'km') {
    dist = dist * 1.609344;
  }
  return dist;
};

/**
 * This function should "draw" the Google map.
 */
const drawStores = function (data) {
  var centerPoint = new google.maps.LatLng(data.latitude, data.longitude);
  var mapOptions = {
    zoom: 13,
    zoomControl: true,
    panControl: true,
    streetViewControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: centerPoint,
    gestureHandling: 'cooperative',
  };
  map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
  /*
     * this ScrollInterceptOverlay is commented out because it's causing issues with the mouse
     * getting "stuck" on the map when the user is scrolling down the page, as described in TREK-2486
     ScrollInterceptOverlay.prototype = new google.maps.OverlayView();
     overlay = new ScrollInterceptOverlay(new google.maps.Map(document
     .getElementById("map_canvas"), mapOptions));
     */
  const searchResultsBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(data.boundSouthLatitude, data.boundWestLongitude),
    new google.maps.LatLng(data.boundNorthLatitude, data.boundEastLongitude)
  );
  if (center) {
    map.setCenter(center);
    map.setZoom(idledZoom);
  } else {
    map.fitBounds(searchResultsBounds);
  }

  google.maps.event.trigger(document.getElementById('map_canvas'), 'resize');

  let timeout;
  google.maps.event.addListener(map, 'idle', function () {
    idledZoom = map.getZoom();
    const position = {coords: {latitude: map.getCenter().lat(), longitude: map.getCenter().lng()}};
    const currentBounds = JSON.stringify(map.getBounds());
    if (currentBounds != lastSearchedBounds && !initial) {
      let distance = calculateDistance(
        map.getCenter().lat(),
        map.getCenter().lng(),
        map.getBounds().getNorthEast().lat(),
        map.getBounds().getNorthEast().lng(),
        distanceUnit
      );
      if (distance > 500) {
        distance = 500;
      }
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function () {
        center = map.getCenter();
        lastSearchedBounds = JSON.stringify(map.getBounds());
        expandSearch = false;
        storeFinderAjaxHelper.storeLatLongAndSearch(position, distance, expandSearch);
      }, 1000);
    }
    initial = false;
  });
};

/**
 * This function should add the stores to the Google map.
 */
const addStore = function (
  storeId,
  coord,
  mapStoreName,
  mapStoreDetailURL,
  mapAddress1,
  mapAddress2,
  mapTown,
  mapPostcode,
  mapAddressUrl,
  mapProductSelection,
  mapname
) {
  var mapAddress = "<div><a href='" + mapStoreDetailURL + "' class='link-strong'>" + mapStoreName + '</a><ul>';

  //if address1 contains commas, split
  var composedAddress = mapAddress1.split(',');
  for (var i = 0; i < composedAddress.length; i++) {
    mapAddress = mapAddress + '<li>' + composedAddress[i] + ', </li>';
  }

  if (mapAddress2 != '' && mapAddress2 != undefined && mapAddress2 != null) {
    mapAddress = mapAddress + '<li>' + mapAddress2 + '</li>';
  }

  if (mapTown != '' && mapTown != undefined && mapTown != null) {
    mapAddress = mapAddress + '<li>' + mapTown;

    if (mapPostcode != '' && mapPostcode != undefined && mapPostcode != null) {
      mapAddress = mapAddress + ', ' + mapPostcode + '</li>';
    } else {
      mapAddress = mapAddress + '</li>';
    }
  }

  if (mapAddressUrl != undefined && mapAddressUrl != null) {
    mapAddress = mapAddress + "<li> <span class='text-strong'>" + mapAddressUrl + '</span></li>';
  }
  mapAddress = mapAddress + '</div>';

  var pinImage = markerLocation + mapProductSelection + '/' + String.fromCharCode(markers.length + 65) + '.png';

  const marker = new google.maps.Marker({
    position: coord,
    map: map,
    title: mapname,
    icon: pinImage,
    something: mapAddress,
  });

  google.maps.event.addListener(marker, 'click', function () {
    infowindow.setContent(this.something);
    infowindow.open(map, this);
  });

  marker.setMap(map);
  markers.push(marker);
};

const addStoreDetails = function (coord) {
  const pinImage = markerLocation + storeProductSelection + '/' + String.fromCharCode(markers.length + 65) + '.png';
  const marker = new google.maps.Marker({
    position: coord,
    map: map,
    title: storename,
    icon: pinImage,
  });
  markers.push(marker);
  if (storeaddressurl === undefined || storeaddressurl === null) {
    infowindow = new google.maps.InfoWindow({
      position: map.getCenter(),
      content:
        "<div><span class='text-strong'>" +
        storeDescription +
        '</span>' +
        '<ul><li>' +
        storeaddressline1 +
        '</li>' +
        '<li>' +
        storeaddressline2 +
        '</li>' +
        '<li>' +
        storeaddresstown +
        '</li>' +
        '<li>' +
        storeaddresspostalCode +
        '</li>' +
        '<li>' +
        storeaddresscountryname +
        '</li>' +
        '</div>',
    });
  } else {
    infowindow = new google.maps.InfoWindow({
      position: map.getCenter(),
      content:
        "<div><span class='text-strong'>" +
        storeDescription +
        '</span>' +
        '<ul><li>' +
        storeaddressline1 +
        '</li>' +
        '<li>' +
        storeaddressline2 +
        '</li>' +
        '<li>' +
        storeaddresstown +
        '</li>' +
        '<li>' +
        storeaddresspostalCode +
        '</li>' +
        '<li>' +
        storeaddresscountryname +
        '</li>' +
        "<li><span class='text-strong'>" +
        storeaddressurl +
        '</span></li></ul>' +
        '</div>',
    });
  }

  google.maps.event.addListener(marker, 'click', function () {
    infowindow.open(map);
  });
};

/**
 * This function should draw the Google map of the store for the store profile page using the
 * passed-in parameters for latitude and longitude.
 */
const drawStore = function (storelatitude, storelongitude) {
  var centerPoint = new google.maps.LatLng(storelatitude, storelongitude);
  var mapOptions = {
    zoom: 13,
    zoomControl: true,
    panControl: true,
    streetViewControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: centerPoint,
    gestureHandling: 'cooperative',
  };
  map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);

  addStoreDetails(new google.maps.LatLng(storelatitude, storelongitude));
};

//This class is used to fix a bug where scrolling on Google Maps will cause the whole window to scroll
//For more information see https://code.google.com/p/gmaps-api-issues/issues/detail?id=3652

const ScrollInterceptOverlay = function (gmap) {
  if (!(this instanceof ScrollInterceptOverlay)) {
    return;
  }
  var $div;
  var $mapDiv;
  var $map;
  var $widthSpacing;
  var $heightSpacing;

  var initialize = function () {
    $div = $('<div />').css({
      display: 'inline-block',
    });

    addEventListeners($div[0]);
    this.setMap(gmap);
  };

  const addEventListeners = function (div) {
    if (div && div.addEventListener) {
      // IE, Opera, Chrome and Safari
      div.addEventListener('mousewheel', mouseScrollStop);
      // Firefox
      div.addEventListener('DOMMouseScroll', mouseScrollStop);
      div.addEventListener('MozMousePixelScroll', mouseScrollStop);
    } else if (div && div.attachEvent) {
      // IE 8 and less
      div.attachEvent('onmousewheel', mouseScrollStop);
    }

    bindMouseDrag('#map_canvas');
  };

  const mouseScrollStop = function (e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  };

  this.onAdd = function () {
    $div.prependTo(this.getPanes().overlayMouseTarget);
  };

  this.onRemove = function () {
    addEventListeners($div[0]);
    $div.detach();
  };

  this.draw = function () {
    var overlayProjection = this.getProjection();
    var sw = overlayProjection.fromLatLngToDivPixel($map.getBounds().getSouthWest());
    var ne = overlayProjection.fromLatLngToDivPixel($map.getBounds().getNorthEast());

    // Reposition the DIV so that it is centered on the map, triggers on zoom in/out
    $div.css({
      position: 'absolute',
      left: sw.x - $widthSpacing,
      top: ne.y - $heightSpacing,
    });
  };

  var bindMouseDrag = function (map) {
    var isDragging = false;

    $(map).mousedown(function () {
      $(window).one('mousemove', function () {
        isDragging = true;
      });
    });

    $(window).mouseup(function () {
      $(window).unbind('mousemove');
      if (isDragging) {
        // Reposition the DIV so that it is centered on the map, triggers on mouse drag
        $div.offset({
          left: $(map).offset().left - $widthSpacing,
          top: $(map).offset().top - $heightSpacing,
        });
        isDragging = false;
      }
    });
  };

  var base_setMap = this.setMap;
  this.setMap = function (map) {
    $map = map;
    $mapDiv = $(map.getDiv());
    $widthSpacing = $mapDiv.outerWidth() / 2;
    $heightSpacing = $mapDiv.outerHeight() / 2;

    if ($mapDiv && $mapDiv.length === 1) {
      // Doubled width and height to ensure that overlay covers the map
      $div.css({
        width: $mapDiv.outerWidth() * 2,
        height: $mapDiv.outerHeight() * 2,
      });
    }

    base_setMap.call(this, map);
  };

  initialize.call(this);
};

// Baidu Integration - Start

const TextMarker = function (point, text, mouseoverText) {
  this._point = point;
  this._text = text;
  this._overText = mouseoverText;
};

const storesMarkerMethod = function (
  map,
  lng,
  lat,
  style,
  txt,
  mapProductSelection,
  mapStoreName,
  mapStoreDetailURL,
  mapAddress1,
  mapAddress2,
  mapTown,
  mapPostcode,
  mapCountryName
) {
  let storeMarker = {};
  storeMarker = map.addMarker(lng, lat, style, txt, mapProductSelection, mapStoreName);
  const sContent =
    "<div><a href='" +
    mapStoreDetailURL +
    "' class='link-strong'>" +
    mapStoreName +
    '</a>' +
    '<ul><li>' +
    mapAddress1 +
    '</li>' +
    '<li>' +
    mapAddress2 +
    '</li>' +
    '<li>' +
    mapTown +
    '</li>' +
    '<li>' +
    mapPostcode +
    '</li>' +
    '<li>' +
    mapCountryName +
    '</li>' +
    '</div>';
  const infoWindow = new BMap.InfoWindow(sContent);
  storeMarker.addEventListener('click', function () {
    this.openInfoWindow(infoWindow);
  });
};

const storeMarkerMethod = function (map, style, txt) {
  let storeMarker = {};
  storeMarker = map.addMarker(storelongitude, storelatitude, style, txt, storeProductSelection, storename);

  const sContent =
    "<div><span class='text-strong'>" +
    storename +
    '</span>' +
    '<ul><li>' +
    storeaddressline1 +
    '</li>' +
    '<li>' +
    storeaddressline2 +
    '</li>' +
    '<li>' +
    storeaddresstown +
    '</li>' +
    '<li>' +
    storeaddresspostalCode +
    '</li>' +
    '<li>' +
    storeaddresscountryname +
    '</li>' +
    '</div>';
  const infoWindow = new BMap.InfoWindow(sContent);
  storeMarker.addEventListener('click', function () {
    this.openInfoWindow(infoWindow);
  });
};

TextMarker.prototype.initialize = function (map) {
  this._map = map;
  const div = (this._div = document.createElement('div'));
  div.style.position = 'absolute';
  div.style.zIndex = BMap.Overlay.getZIndex(this._point.lat);
  div.style.backgroundColor = '#EE5D5B';
  div.style.border = '1px solid #BC3B3A';
  div.style.color = 'white';
  div.style.height = '18px';
  div.style.padding = '2px 10px';
  div.style.lineHeight = '18px';
  div.style.whiteSpace = 'nowrap';
  div.style.MozUserSelect = 'none';
  div.style.fontSize = '12px';
  const span = (this._span = document.createElement('span'));
  div.appendChild(span);
  span.appendChild(document.createTextNode(this._text));
  const that = this;

  const arrow = (this._arrow = document.createElement('div'));
  arrow.style.background =
    'url(https://map.baidu.com/fwmap/upload/r/map/fwmap/static/house/images/label.png) no-repeat';
  arrow.style.position = 'absolute';
  arrow.style.width = '11px';
  arrow.style.height = '10px';
  arrow.style.top = '22px';
  arrow.style.left = '10px';
  arrow.style.overflow = 'hidden';
  div.appendChild(arrow);

  div.onmouseover = function () {
    this.style.backgroundColor = '#6BADCA';
    this.style.borderColor = '#0000ff';
    this.style.cursor = 'pointer';
    this.getElementsByTagName('span')[0].innerHTML = that._overText;
    arrow.style.backgroundPosition = '0px -20px';
  };

  div.onmouseout = function () {
    this.style.backgroundColor = '#EE5D5B';
    this.style.borderColor = '#BC3B3A';
    this.getElementsByTagName('span')[0].innerHTML = that._text;
    arrow.style.backgroundPosition = '0px 0px';
  };

  map.getPanes().labelPane.appendChild(div);

  div._marker = this;

  return div;
};
TextMarker.prototype.draw = function () {
  var map = this._map;
  var pixel = map.pointToOverlayPixel(this._point);
  this._div.style.left = pixel.x - parseInt(this._arrow.style.left) + 'px';
  this._div.style.top = pixel.y - 30 + 'px';
};
TextMarker.prototype.addEventListener = function (evt, handler) {
  this._div.addEventListener(evt, handler);
};

$.fn.bmap = function (options) {
  var mapInstance = $.extend(
    {
      initOptions: {minZoom: 5},
      _bmap_centerPoint: new BMap.Point(storeSearchPageDatasourceLatitude, storeSearchPageDatasourceLongitude),
      centerAt: function (lng, lat) {
        this._bmap_centerPoint = new BMap.Point(lng, lat);
      },
      zoomLevel: 5,
      viewPoints: new Array(),
      addMarker: function (lng, lat, style, txt, productSelection, mapname) {
        var level = productSelection.toLowerCase();

        var point = new BMap.Point(lng, lat);
        this.viewPoints.push(point);
        var marker;

        if (style) {
          if (style == 'Bubble') {
            var icon = new BMap.Icon(markerLocation + level + '/' + txt + '.png', new BMap.Size(32, 45), {});
            marker = new BMap.Marker(point, {
              icon: icon,
              title: name,
            });
          }
        } else {
          var marker = new BMap.Marker(point);
        }
        this._bmap.addOverlay(marker);
        return marker;
      },
      clearMarkers: function () {
        this._bmap.clearOverlays();
        this.viewPoints = new Array();
      },
      centerAndZoom: function () {
        this._bmap.centerAndZoom(this._bmap_centerPoint, this.zoomLevel);
      },
      resetViewport: function () {
        if (this.viewPoints.length > 0) {
          this._bmap.setViewport(this.viewPoints);
        } else {
          this.centerAndZoom();
        }
      },
    },
    options
  );
  mapInstance._bmap = new BMap.Map(this[0], mapInstance.initOptions);
  mapInstance._bmap.disableScrollWheelZoom();
  mapInstance._bmap.addControl(new BMap.NavigationControl());
  return mapInstance;
};

/* Treck Ajax Store Finder */

const storeFinderAjaxHelper = (function () {
  var myAjaxUrl = null;
  var myCompiledStores = null;
  var myCompiledSorts = null;
  var myCompiledPageination = null;

  function configure(ajaxUrl, themeResourcePath) {
    var cdnFilesPath = $('#cdnFilesPath').val();
    var storesHbs = cdnFilesPath + '/js/handlebars/storeLocatorStores.hbs';
    var sortHbs = cdnFilesPath + '/js/handlebars/storeLocatorSort.hbs';
    var pageHbs = cdnFilesPath + '/js/handlebars/storeLocatorPagination.hbs';

    bindEvents();
    myAjaxUrl = ajaxUrl;

    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
      };
    }

    //Create hbs helper methods
    Handlebars.registerHelper('counter', function (index) {
      return index + 1;
    });

    Handlebars.registerHelper('getDialablePhoneNumber', function (phone) {
      var dialbleNumber = phone.match(/\d/g);
      dialbleNumber = dialbleNumber ? dialbleNumber.join('') : phone;
      return phone.trim().startsWith('+') ? '+' + dialbleNumber : dialbleNumber;
    });

    Handlebars.registerHelper('getmarkerImageUrl', function (currentProductSelection, index) {
      var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (!currentProductSelection) {
        currentProductSelection = 'GOOD';
      }
      const markerImageUrlPath =
        themeResourcePath +
        '/images/markers/' +
        currentProductSelection.toLowerCase() +
        '/' +
        alphabet.charAt(index) +
        '.png';

      window.markerImageUrlPath = markerImageUrlPath;
      return markerImageUrlPath;
    });
    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
      switch (operator) {
        case '==':
          return v1 == v2 ? options.fn(this) : options.inverse(this);
        case '===':
          return v1 === v2 ? options.fn(this) : options.inverse(this);
        case '!=':
          return v1 != v2 ? options.fn(this) : options.inverse(this);
        case '!==':
          return v1 !== v2 ? options.fn(this) : options.inverse(this);
        case '<':
          return v1 < v2 ? options.fn(this) : options.inverse(this);
        case '<=':
          return v1 <= v2 ? options.fn(this) : options.inverse(this);
        case '>':
          return v1 > v2 ? options.fn(this) : options.inverse(this);
        case '>=':
          return v1 >= v2 ? options.fn(this) : options.inverse(this);
        case '&&':
          return v1 && v2 ? options.fn(this) : options.inverse(this);
        case '||':
          return v1 || v2 ? options.fn(this) : options.inverse(this);
        default:
          return options.inverse(this);
      }
    });

    Handlebars.registerHelper('math', function (lvalue, operator, rvalue, options) {
      lvalue = parseFloat(lvalue);
      rvalue = parseFloat(rvalue);

      return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue,
      }[operator];
    });

    Handlebars.registerHelper('escapeSQ', function (sVal) {
      return sVal.replace(/'/g, "\\'").replace(/"/g, '\\"');
    });

    Handlebars.registerHelper('srcsetSources', function (assetId) {
      if (assetId.startsWith('http')) {
        return "src='" + assetId + "'";
      }

      const breakpoints = [480, 640, 768, 1024, 1200, 1440, 1500];

      const aspectRatioDec = 3 / 4;
      let asset = assetId.replace(/\s/g, '%20');
      const baseUrl = '//media.trekbikes.com/image/upload/';

      const urlRegex = new RegExp(
        /(http(s)?:\/\/.)?(\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.,~#?&//=]*)/
      );
      if (urlRegex.test(asset)) {
        const splitUrl = asset.split('/');
        asset = splitUrl[splitUrl.length - 1];
      }

      const sourcesArray = breakpoints.map((width) => {
        return `${baseUrl}f_auto,w_${width},h_${Math.floor(width * aspectRatioDec)},c_pad/${asset} ${width}w`;
      });

      const sources = sourcesArray.join(',');
      const maxBreakpoint = breakpoints.length - 1;

      const srcset = `data-srcset='${sources}',  
        data-src= '${baseUrl}f_auto,w_${maxBreakpoint},h_${Math.floor(maxBreakpoint * aspectRatioDec)},c_pad/${asset}' 
        data-asset-id='${asset}'`;

      return srcset;
    });

    Handlebars.registerHelper('prevDayDiff', function (weekDayOpeningList, idx, options) {
      if (idx - 1 >= 0) {
        if (weekDayOpeningList[idx - 1].weekDay == weekDayOpeningList[idx].weekDay) {
          return options.inverse(this);
        }
      }
      return options.fn(this);
    });

    //Execute the template
    $.ajax({
      url: storesHbs,
      cache: false,
      success: function (hbsContent) {
        myCompiledStores = Handlebars.compile(hbsContent);
      },
    });

    $.ajax({
      url: sortHbs,
      cache: false,
      success: function (hbsContent) {
        myCompiledSorts = Handlebars.compile(hbsContent);
      },
    });

    $.ajax({
      url: pageHbs,
      cache: false,
      success: function (hbsContent) {
        myCompiledPageination = Handlebars.compile(hbsContent);
      },
    });

    //1) Bind all click events.

    //2) Load hbs templates.
  }

  function searchByZip() {
    const queryObject = {};
    $('#latitude').val(null);
    $('#longitude').val(null);
    center = null;
    addZipOrCoords(queryObject);
    addFilters(queryObject);
    doSearch(queryObject);
  }

  function searchAgainByZip() {
    const queryObject = {};
    $('#latitude').val(null);
    $('#longitude').val(null);
    center = null;
    addZipOrCoords(queryObject);
    addFilters(queryObject);
    addSorts(queryObject);
    //Remove the distance sort filter in a search again to avoid 0 results.
    delete queryObject.distance;
    doSearch(queryObject);
  }

  function searchNearMe() {
    const gps = navigator.geolocation;
    center = null;
    if (gps) {
      gps.getCurrentPosition(storeLatLongAndSearch, function (error) {
        // console.log("An error occurred... The error code and message are: " + error.code + "/" + error.message);
      });
    }
  }

  function storeLatLongAndSearch(position, distance, expandSearch) {
    const queryObject = {};
    $('#storelocator-query').val(null);
    $('#latitude').val(position.coords.latitude);
    $('#longitude').val(position.coords.longitude);

    queryObject.latitude = position.coords.latitude;
    queryObject.longitude = position.coords.longitude;

    queryObject.top = map.getBounds().getNorthEast().lat();
    queryObject.bottom = map.getBounds().getSouthWest().lat();
    queryObject.left = map.getBounds().getSouthWest().lng();
    queryObject.right = map.getBounds().getNorthEast().lng();

    addZipOrCoords(queryObject);
    addFilters(queryObject);
    addSorts(queryObject);

    if (distance) {
      queryObject.distance = distance;
      saveStoreFinderQueryData(queryObject);
    }

    if (expandSearch) {
      $('#expandSearch').val(expandSearch);
      queryObject.expandSearch = expandSearch;
    }

    var pageTo = $('#page-holder').data('page')
      ? $('#page-holder').data('page')
      : $('#store-finder-current-page').data('pagination-to');
    if (pageTo) {
      queryObject.page = pageTo;
    }

    doSearch(queryObject);
  }

  function searchAndSort(event) {
    const queryObject = {};
    initial = true;
    if (!$('#storelocator-query').val()) {
      const position = {coords: {latitude: map.getCenter().lat(), longitude: map.getCenter().lng()}};
      storeLatLongAndSearch(position);
    } else {
      addZipOrCoords(queryObject);
      addFilters(queryObject);
      addSorts(queryObject);

      const pageTo = $('#store-finder-current-page').data('pagination-to');
      if (pageTo) {
        queryObject.page = pageTo;
      }
      doSearch(queryObject);
    }
  }

  function searchToPage(elm) {
    const queryObject = {};

    // Store the page that the user is trying to go to via pagination.
    $('#page-holder').data('page', $(elm).parent().data('pagination-to'));

    if (!$('#storelocator-query').val()) {
      const position = {coords: {latitude: map.getCenter().lat(), longitude: map.getCenter().lng()}};
      const localStorageQueryData = getStoreFinderQueryData();
      storeLatLongAndSearch(position, localStorageQueryData.distance);
    } else {
      addZipOrCoords(queryObject);
      addFilters(queryObject);
      addSorts(queryObject);

      const pageTo = $(elm).parent().data('pagination-to');
      if (pageTo) {
        queryObject.page = pageTo;
      }
      doSearch(queryObject);
    }
  } //Retain all values

  function addZipOrCoords(queryObject) {
    if (queryObject.latitude && queryObject.longitude) {
      queryObject.q = '';
    } else {
      queryObject.q = $('#storelocator-query').val();
    }
  }

  function addFilters(queryObject) {
    $.each($("input[class='facet-group__check']:checked"), function () {
      const currentCheckbox = $(this);
      const cbName = currentCheckbox.attr('name');
      const cbValue = currentCheckbox.prop('value');
      queryObject[cbName] = cbValue;
    });
  }

  function selectFilters(queryObject) {
    for (const key in queryObject) {
      if (queryObject.hasOwnProperty(key) && queryObject[key] == 'on') {
        const input = $('#' + key);
        if (input && input.attr('type') == 'checkbox') {
          input.click();
        }
      }
    }
  }

  function addSorts(queryObject) {
    if ($('#sortby').val()) {
      queryObject.sort = $('#sortby').val();
    }

    if ($('#distance').val()) {
      queryObject.distance = $('#distance').val();
    }
  }

  function doSearch(queryData) {
    window.loadingIndicator.show();
    // Remove any global messages that may be currently present on the page.
    $('#globalMessages').html('');

    $.ajax({
      url: myAjaxUrl,
      data: queryData,
      type: 'GET',
      dataType: 'json',
      error: function () {
        //Handle error...
        window.loadingIndicator.hide();
      },
      success: function (data) {
        distanceUnit = data.distanceUnit;
        //Handle what to do with data
        // Check to make sure that the searchPageData object is not null AND
        // that the results array contains at least 1 store.  If conditions are not
        // met then we display an error message at the top.
        if (data.searchPageData && data.searchPageData.results.length > 0) {
          if (queryData.q !== '') {
            initial = true;
          }
          processResponse(data);
          saveStoreFinderQueryData(queryData);
        } else {
          showErrorMessage(data);
        }
        window.loadingIndicator.hide();
      },
    });
  }

  function getStoreFinderQueryData() {
    if (localStorage.storeFinderQueryData) return JSON.parse(localStorage.storeFinderQueryData);
  }

  function saveStoreFinderQueryData(storeFinderQueryData) {
    localStorage.storeFinderQueryData = JSON.stringify(storeFinderQueryData);
  }

  /**
   * This function will display the error message in the global messages area if no stores are found, similar to how it was before
   * moving the page to AJAX calls.
   */
  function showErrorMessage(data) {
    // Set the error message.
    const errorMessage =
      '<div class="row expand"><ul><li class="alert negative">' +
      data.jsMessageBundle['storelocator.error.no.results.subtitle'] +
      '</li></ul></div>';

    // Remove the map and all the results left over if there was a successful search before.
    deleteMarkers();
    $('#bmap-container').addClass('hide');

    $('.store-refinements__search-button-holder').addClass('hide');
    $('.store-locator__stores').html('');

    // Display the error message in the global messages area.
    $('#globalMessages').html(errorMessage);

    scrollToMessages();
  }

  function processResponse(data) {
    refreshMap(data);

    $('.store-locator__stores').html('');
    refreshSortOptions(data);
    refreshSearchList(data);

    refreshPagination(data);

    // Draw the Google map of store markers.
    if (data.baiduMapApiVersion && data.currentLanguage === 'zh_CN') {
      drawStoresOnBaiduMap(data);
    } else {
      drawStoresOnMap(data);
    }

    // Scroll to the store list.
    scrollToStoreList();
  }

  function refreshMap() {
    deleteMarkers();
    //For showing markers again need to mimic the drawStoresFinal() method from the storesMap.tag file here with the ajax response info
  }

  function setMapOnAll(map) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  function clearMarkers() {
    setMapOnAll(null);
  }

  // Shows any markers currently in the array.
  function showMarkers() {
    setMapOnAll(map);
  }

  // Deletes all markers in the array by removing references to them.
  function deleteMarkers() {
    clearMarkers();
    markers = [];
  }

  function refreshSearchList(data) {
    const html = myCompiledStores(data);
    $('.store-locator__stores').append(html);
  }

  function refreshSortOptions(data) {
    const html = myCompiledSorts(data);
    $('.store-locator__stores').append(html);
  }

  function refreshPagination(data) {
    const html = myCompiledPageination(data);
    $('.store-locator__stores').append(html);

    $('.store-locator__stores .pagination__cell a ').click(function (event) {
      event.preventDefault();
      storeFinderAjaxHelper.searchToPage(this);
    });
  }

  // Function that will scroll the browser to the store list.
  function scrollToStoreList() {
    // Display the "Search again" button
    $('.store-refinements__search-button-holder').removeClass('hide');

    // Stop the auto scroll if the user starts to manually scroll.
    $('html, body').on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
      $('html, body').stop();
    });
    //Scroll to anchor point, if the user is not on a desktop.
    if (window.innerWidth < 768 && !center) {
      $('html, body')
        .stop()
        .animate({scrollTop: $('#results-section-anchor').offset().top}, 'fast');
    }
  }

  // Function that will scroll the browser to the top of the page if there is an error.
  function scrollToMessages() {
    // Stop the auto scroll if the user starts to manually scroll.
    $('html, body').on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
      $('html, body').stop();
    });

    $('html,body')
      .stop()
      .animate({scrollTop: $('body').offset().top}, 'slow');
  }

  function bindEvents() {
    $('#findStores').click(function (event) {
      event.preventDefault();
      storeFinderAjaxHelper.searchByZip();
    });

    // Bind the event for when the "Search again" button is clicked by
    // the user.
    $('#findStoresAgain').click(function (event) {
      event.preventDefault();

      // When the "search again" button is clicked, we want to determine whether the user had used
      // the search by zipcode or the find stores near me functionality.  Then call the appropriate method to
      // refine the search results.
      if ($('#storelocator-query').val()) {
        storeFinderAjaxHelper.searchAgainByZip();
      } else {
        storeFinderAjaxHelper.searchNearMe();
      }
      //Scroll to anchor point
      if ($('#map-container').hasClass('hide')) {
        $('html,body')
          .stop()
          .animate({scrollTop: $('#bmap-container').offset().top - 100}, 'fast');
      } else {
        $('html,body')
          .stop()
          .animate({scrollTop: $('#map-container').offset().top - 100}, 'fast');
      }
    });

    $('#findStoresNearMeAjax').click(function (event) {
      event.preventDefault();
      storeFinderAjaxHelper.searchNearMe();
    });

    $('.store-locator__stores .pagination__cell a').click(function (event) {
      event.preventDefault();
      storeFinderAjaxHelper.searchToPage(this);
    });
  }

  /**
   * This function will draw the markers for stores on the Google map.
   */
  function drawStoresOnMap(currentData) {
    // Setup variables that Google Maps API will need.
    const storeSearchPageDatasourceLatitude = currentData.searchPageData.sourceLatitude;
    const storeSearchPageDatasourceLongitude = currentData.searchPageData.sourceLongitude;
    const storeSearchPageDataboundSouthLatitude = currentData.searchPageData.boundSouthLatitude;
    const storeSearchPageDataboundWestLongitude = currentData.searchPageData.boundWestLongitude;
    const storeSearchPageDataboundNorthLatitude = currentData.searchPageData.boundNorthLatitude;
    const storeSearchPageDataboundEastLongitude = currentData.searchPageData.boundEastLongitude;
    const storeSearchPageDataresults = currentData.searchPageData.results;
    const storeSearchPageDatacurrentDistance = currentData.filterValue;

    const googleParameters = {
      latitude: storeSearchPageDatasourceLatitude,
      longitude: storeSearchPageDatasourceLongitude,
      boundSouthLatitude: storeSearchPageDataboundSouthLatitude,
      boundWestLongitude: storeSearchPageDataboundWestLongitude,
      boundNorthLatitude: storeSearchPageDataboundNorthLatitude,
      boundEastLongitude: storeSearchPageDataboundEastLongitude,
      currentDistance: storeSearchPageDatacurrentDistance,
    };

    // Draw the Google map, passing in the above JS object with properties.
    drawStores(googleParameters);

    // Loop through the results array using the JS forEach.
    currentData.searchPageData.results.forEach(function (currentValue, index) {
      if (currentValue.address.line1 === undefined || currentValue.address.line1 === null) {
        currentValue.address.line1 = '';
      }
      // Add the store to the Google map.
      if (!currentValue.productSelection) {
        currentValue.productSelection = 'GOOD';
      }
      addStore(
        index,
        new google.maps.LatLng(currentValue.geoPoint.latitude, currentValue.geoPoint.longitude),
        currentValue.description,
        currentValue.url,
        currentValue.address.line1,
        currentValue.address.line2,
        currentValue.address.town,
        currentValue.address.postalCode,
        currentValue.address.url,
        currentValue.productSelection.toLowerCase(),
        currentValue.name
      );
    });

    // Once we have "drawn" the map and all the stores, display the map on the page.
    $('#map-container').removeClass('hide');
  }

  function drawStoresOnBaiduMap(currentData) {
    var map = $('#bmap_canvas').bmap();
    $('#bmap-container').removeClass('hide');
    map.clearMarkers();
    map.centerAndZoom();

    var singlePosIdx;
    for (singlePosIdx in currentData.searchPageData.results) {
      var singlePos = currentData.searchPageData.results[singlePosIdx];
      var letterVar = '&#' + ((singlePosIdx % 26) + 65) + ';';

      if (singlePos.address.line1 === undefined || singlePos.address.line1 === null) {
        singlePos.address.line1 = '';
      }

      storesMarkerMethod(
        map,
        singlePos.geoPoint.longitude,
        singlePos.geoPoint.latitude,
        'Bubble',
        letterVar,
        singlePos.productSelection,
        singlePos.description,
        singlePos.url,
        singlePos.address.line1,
        singlePos.address.line2,
        singlePos.address.town,
        singlePos.address.postalCode,
        singlePos.address.country.name
      );
    }
    map.resetViewport();
    // Once we have "drawn" the map and all the stores, display the map on the page
    $('#bmap-container').removeClass('hide');
  }

  // Reveal public pointers to
  // private functions and properties

  return {
    searchByZip: searchByZip,
    searchAgainByZip: searchAgainByZip,
    searchAndSort: searchAndSort,
    configure: configure,
    searchToPage: searchToPage,
    searchNearMe: searchNearMe,
    bindEvents: bindEvents,
    doSearch: doSearch,
    selectFilters: selectFilters,
    getStoreFinderQueryData: getStoreFinderQueryData,
    storeLatLongAndSearch: storeLatLongAndSearch,
  };
})();

window.drawStore = drawStore;
window.ScrollInterceptOverlay = ScrollInterceptOverlay;
window.TextMarker = TextMarker;
window.storesMarkerMethod = storesMarkerMethod;
window.storeMarkerMethod = storeMarkerMethod;
window.storeFinderAjaxHelper = storeFinderAjaxHelper;
window.getQueryParameters = getQueryParameters;
window.getQueryString = getQueryString;
window.calculateDistance = calculateDistance;
window.drawStores = drawStores;
window.addStoreDetails = addStoreDetails;
window.addStore = addStore;
