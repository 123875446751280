ACC.removepayment = {
  bindAll: function () {
    this.bindRemovePaymentDetails();
  },

  bindRemovePaymentDetails: function () {
    $('.submitRemove').on('click', function () {
      $('#removePaymentDetails' + $(this).attr('id')).submit();
    });
    $('.submitSetDefault').on('click', function () {
      $('#setDefaultPaymentDetails' + $(this).attr('id')).submit();
    });
  },

  removePaymentDetails: function (event, paymentInfoId) {
    event.preventDefault();
    // language encoding for url
    var encodedContextPath = window.ACC.config.encodedContextPath;
    $.ajax({
      url: encodedContextPath + '/checkout/payment/removePaymentInfo',
      type: 'POST',
      data: {paymentInfoId: paymentInfoId},
      success: function (response) {
        if (response.redirect) {
          window.location.assign(response.redirectURL);
        } else {
          // the id includes the paymentInfo.id and is dynamic
          var paymentInfoContainerSelector = '#paymentInfoContainer-' + paymentInfoId;
          // get the message from the tag file with translations
          var removePaymentMethodMessage = $('#removePaymentMethodMessage').val();
          $(paymentInfoContainerSelector).remove();
          window.vm.toast({type: 'success', message: removePaymentMethodMessage, showClose: true});
        }
      },
      error: function (error) {
        var removePaymentErrorMessage = $('#removePaymentErrorMessage').val();
        window.vm.toast({type: 'error', message: removePaymentErrorMessage, showClose: true});
        console.log(removePaymentErrorMessage);
      },
    });
  },
};
$(window).on('load', function() { 
  ACC.removepayment.bindAll(); 
});