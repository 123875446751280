var isOptinMonsterEnabled = $("#isOptinMonsterEnabled")
if (isOptinMonsterEnabled && isOptinMonsterEnabled.val() === "true") {
    var om57193e8c45f7c, om57193e8c45f7c_poll = function () {
        var r = 0;
        return function (n, l) {
            clearInterval(r), r = setInterval(n, l)
        }
    }();
    !function (e, t, n) {
        if (e.getElementById(n)) {
            om57193e8c45f7c_poll(function () {
                if (window['om_loaded']) {
                    if (!om57193e8c45f7c) {
                        om57193e8c45f7c = new OptinMonsterApp();
                        return om57193e8c45f7c.init({"s": "18058.57193e8c45f7c", "staging": 0, "dev": 0, "beta": 0});
                    }
                }
            }, 25);
            return;
        }
        var d = false, o = e.createElement(t);
        o.id = n, o.src = "//a.optnmstr.com/app/js/api.min.js", o.async = true, o.onload = o.onreadystatechange = function () {
            if (!d) {
                if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
                    try {
                        d = om_loaded = true;
                        om57193e8c45f7c = new OptinMonsterApp();
                        om57193e8c45f7c.init({"s": "18058.57193e8c45f7c", "staging": 0, "dev": 0, "beta": 0});
                        o.onload = o.onreadystatechange = null;
                    } catch (t) {
                    }
                }
            }
        };
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(o)
    }(document, "script", "omapi-script");
}